export const AAVE_ORACLE_ABI = [
    {
      "inputs":[
         {
            "internalType":"address",
            "name":"asset",
            "type":"address"
         }
      ],
      "name":"getAssetPrice",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
   {
      "inputs":[

      ],
      "name":"BASE_CURRENCY_UNIT",
      "outputs":[
         {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
         }
      ],
      "stateMutability":"view",
      "type":"function"
   },
]

export const AAVE_ORACLE_ADDRESS = '0x54586bE62E3c3580375aE3723C145253060Ca0C2'