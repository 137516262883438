// VaultPopup.js
import React, { useState } from 'react';
import Header from './Header';
import VaultActions from './VaultActions'; // This will include the Deposit and Withdraw buttons
import VaultInfo from './VaultInfo';
import './VaultPopup.css';

const VaultPopup = () => {
  // State to manage which tab is active
  const [activeTab, setActiveTab] = useState('deposit');

  return (
    <div className="popup">
      <Header />
      <div className="content">
        <VaultInfo/>
        <VaultActions activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </div>
  );
};

export default VaultPopup;
