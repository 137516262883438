// ActionsSection.js
import React from 'react';
import './VaultActions.css';
import DepositSection from './DepositSection';
import WithdrawSection from './WithdrawSection';

const VaultActions = ({ activeTab, setActiveTab }) => {
  return (
    <div className="actions">
      <div className="tabs">
        <button
          onClick={() => setActiveTab('deposit')}
          className={`tab ${activeTab === 'deposit' ? 'active' : ''}`}
        >
          Deposit
        </button>
        <button
          onClick={() => setActiveTab('withdraw')}
          className={`tab ${activeTab === 'withdraw' ? 'active' : ''}`}
        >
          Withdraw
        </button>
      </div>
      {activeTab === 'deposit' ?
        // Deposit Form JSX goes here
        <DepositSection/>
       :
        // Withdraw Form JSX goes here
        <WithdrawSection/>
      }
    </div>
  );
};


export default VaultActions;

