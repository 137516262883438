import "./VaultPopup.css"
import "./Guidance.css"
import {MINT_AMOUNT_ETH} from "./utils/Wallet"

const Guidance = () => {
  return (
    <div className="popup">
      <h2 className="guidance-header">Usage guide</h2>

      <ul className="guidance">
        <li>Configure testnet</li>
        <ol>
          <li>Log in to MetaMask and open your list of networks.</li>
          <li>
            Press <i>Add network</i>.
          </li>
          <li>
            Scroll down and select <i>Add a network manually</i>.
          </li>
          <li>
            Fill in the network settings as described and press <i>Save</i>.
          </li>

          <table>
            <tr>
              <td>Network name</td>
              <td>power_lido_testnet</td>
            </tr>
            <tr>
              <td>New RPC URL</td>
              <td>https://testnetrpc.leveragedstake.com/</td>
            </tr>
            <tr>
              <td>Chain ID</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Currency symbol</td>
              <td>ETH</td>
            </tr>
          </table>

          <li>Switch to that network.</li>
        </ol>
        
        <li>Prepare your wallet</li>
        <ol>
          <li>
            Scroll down to the interface and press the button <i>Connect to MetaMask</i>.
          </li>
          <li> Button <i>Connect</i> has to change to <i>Connected: 0xYOUR_ADDRESS</i>.
          </li>
          <li>
            Mint some test ether for your account. For that use <i>Mint {MINT_AMOUNT_ETH} ether</i> button.
          </li>
        </ol>
        <li>Play with the Vault!</li>
        <ol>
          <li>
            Deposit some ether in <i>Deposit</i> tab (check for the TBL token in your wallet!).
          </li>
          <li>
            Withdraw some TBL in <i>Withdraw</i> tab.
          </li>
        </ol>
      </ul>
    </div>
  );
};

export default Guidance;
