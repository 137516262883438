import React, { useEffect } from 'react';
import './Header.css';
import { useBlockchainData } from './utils/BlockchainDataContext';

const Header = () => {
  const { totalAssets, wEthPriceUsd } = useBlockchainData()

  const vaultTVLETH = totalAssets ? Math.round(totalAssets) : 0
  const vaultTVLUSD = vaultTVLETH ? vaultTVLETH * wEthPriceUsd : 0

  return (
    <header className="header">
      {/* Add vault logo here if needed */}
      <div className="asset-info">
        <h1>Power Lido vault</h1>
        <div className='subtitle-section'>
          <div className='subtitle'>
            <span className="key"> TVL: </span>
            <span className="val"> {vaultTVLETH.toLocaleString('en-US', { style: 'currency', currency: 'ETH' })}
              <span className="secondary">/ {vaultTVLUSD.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
            </span>
          </div>

          <div className='subtitle'>
            <span className="key"> APY: </span>
            <span className="val"> 14% </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
