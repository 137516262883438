// IntegratedProtocolsSection.jsx
import React from 'react';
import './IntegratedProtocolsSection.css';

const protocols = [
  { name: 'Aave V3', icon: 'aave-logo.png' },
  { name: 'Aave V2', icon: 'aave-logo.png' },
  { name: 'Morpho Blue', icon: 'morpho-logo.png' },
  { name: 'Compound v3', icon: 'compound-logo.png' },
  { name: 'Spark', icon: 'spark-logo.png'}
  // Add other protocols here...
];

const IntegratedProtocolsSection = () => {
  return (
    <div className="integrated-protocols-section">
      <h3>Integrated Protocols</h3>
      <div className="protocols-list">
        {protocols.map((protocol) => (
          <div key={protocol.name} className="protocol-label">
            <img src={protocol.icon} alt={protocol.name} />
            <span>{protocol.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegratedProtocolsSection;
