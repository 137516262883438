// BlockchainDataContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import {
  fetchTotalSupply,
  fetchUserEthBalance,
  fetchTokenBalance,
  fetchTotalAssets,
  fetchMaxDeposit,
  fetchMaxRedeem,
  fetchMaxLstDeposit,
  fetchTokenAllowance,
  fetchAssetPrice,
  fetchTargetLeverage,
  fetchWithdrawFee
} from "./Utils";
import {
  stETHContractAddress,
  wstETHContractAddress,
  wEthContractAddress,
  erc20ABI,
} from "./VaultContract";

// Create the context
const BlockchainDataContext = createContext(null);

// Provider component that fetches the data and provides it to children
export const BlockchainDataProvider = ({ children }) => {
  const [account, setAccount] = useState("");
  const [web3, setWeb3] = useState();
  const [totalSupply, setTotalSupply] = useState("");
  const [userEthBalance, setUserEthBalance] = useState("0");
  const [stETHBalance, setStETHBalance] = useState("0");
  const [wstETHBalance, setWstETHBalance] = useState("0");
  const [wEthBalance, setWEthBalance] = useState(0);
  const [totalAssets, setTotalAssets] = useState("0");
  const [maxDepositEth, setMaxDepositEth] = useState()
  const [maxDepositLst, setMaxDepositLst] = useState()
  const [maxRedeemPWL, setMaxRedeemPWL] = useState()
  const [wEthAllowance, setWEthAllowance] = useState(0)
  const [wstEthAllowance, setWstEthAllowance] = useState(0);
  const [wEthPriceUsd, setWEthPriceUsd] = useState(0)
  const [wstEthPriceUsd, setWstEthPriceUsd] = useState(0)
  const [targetLeverage, setTargetLeverage] = useState(0)
  const [withdrawFee, setWithdrawFee] = useState(0)


  const loadTokenStates = async () => {
    if (account) {
      const stETHBalance = await fetchTokenBalance(
        web3,
        stETHContractAddress,
        account
      );
      setStETHBalance(stETHBalance);

      const wstETHBalance = await fetchTokenBalance(
        web3,
        wstETHContractAddress,
        account
      );
      setWstETHBalance(wstETHBalance);

      const weth = await fetchTokenBalance(
        web3,
        wEthContractAddress,
        account
      )
      setWEthBalance(weth)

      setWstEthAllowance(await fetchTokenAllowance(web3, wstETHContractAddress, account))
      setWEthAllowance(await fetchTokenAllowance(web3, wEthContractAddress, account))
    }
  };

  const loadContractData = async () => {
    const totalAssets = await fetchTotalAssets(web3);
    const totalAssetsInEther = web3.utils.fromWei(totalAssets, "ether");

    setTotalAssets(totalAssetsInEther);
    setMaxDepositEth(await fetchMaxDeposit(web3))
    setMaxDepositLst(await fetchMaxLstDeposit(web3))
    setMaxRedeemPWL(await fetchMaxRedeem(web3, account))
    setTargetLeverage(await fetchTargetLeverage(web3))
    setWithdrawFee(await fetchWithdrawFee(web3))
    // Set state with totalAssets or handle data as needed
  };

  const updatePrices = async () => {
    setWEthPriceUsd(await fetchAssetPrice(web3, wEthContractAddress))
    setWstEthPriceUsd(await fetchAssetPrice(web3, wstETHContractAddress))
  }

  // Function to fetch all required blockchain data
  const loadBlockchainData = async () => {

    if (account) {
      const supply = await fetchTotalSupply(web3);
      setTotalSupply(supply);
      const ethBalance = await fetchUserEthBalance(web3, account);
      setUserEthBalance(ethBalance);
      loadTokenStates();
      loadContractData();
      updatePrices();
    }
  };

  useEffect(() => {
    if (!web3) {
      return
    }
    loadBlockchainData();

    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        console.log("Please connect to MetaMask.");
      } else if (accounts[0] !== account) {
        setAccount(accounts[0]);
      }
    };

    window.ethereum.on("accountsChanged", handleAccountsChanged);

    // Clean up the event listener when the component is unmounted
    return () => {
      if (window.ethereum.removeListener) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  }, [account, web3]);

  return (
    <BlockchainDataContext.Provider
      value={{
        loadBlockchainData,
        account,
        totalSupply,
        userEthBalance,
        setAccount,
        web3,
        setWeb3,
        stETHBalance,
        wstETHBalance,
        wEthBalance,
        totalAssets,
        maxDepositEth,
        maxDepositLst,
        maxRedeemPWL,
        wEthAllowance,
        wstEthAllowance,
        wEthPriceUsd,
        wstEthPriceUsd,
        targetLeverage,
        withdrawFee
      }}
    >
      {children}
    </BlockchainDataContext.Provider>
  );
};

// Custom hook to use the blockchain data context
export const useBlockchainData = () => useContext(BlockchainDataContext);
