export const VaultContractAddress =
  "0x14dDC655A2f48cc0A4160A8C59B33ad6b67dE05E"; // The address for your contract
export const VaultContractABI = [
  {
    type: "function",
    name: "_init",
    inputs: [
      {
        name: "feeCollector",
        type: "address",
        internalType: "address",
      },
      {
        name: "strategyAddress",
        type: "address",
        internalType: "address",
      },
      {
        name: "_withdrawFee",
        type: "tuple",
        internalType: "struct Fee.Data",
        components: [
          { name: "value", type: "uint256", internalType: "uint256" },
          {
            name: "precision",
            type: "uint256",
            internalType: "uint256",
          },
        ],
      },
      {
        name: "dexSlippage",
        type: "tuple",
        internalType: "struct Fee.Data",
        components: [
          { name: "value", type: "uint256", internalType: "uint256" },
          {
            name: "precision",
            type: "uint256",
            internalType: "uint256",
          },
        ],
      },
      {
        name: "addressProvider",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "allowance",
    inputs: [
      { name: "owner", type: "address", internalType: "address" },
      { name: "spender", type: "address", internalType: "address" },
    ],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "approve",
    inputs: [
      { name: "spender", type: "address", internalType: "address" },
      { name: "value", type: "uint256", internalType: "uint256" },
    ],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "asset",
    inputs: [],
    outputs: [
      {
        name: "assetTokenAddress",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "auction",
    inputs: [{ name: "auctionId", type: "uint256", internalType: "uint256" }],
    outputs: [
      {
        name: "",
        type: "tuple",
        internalType: "struct AuctionData",
        components: [
          { name: "id", type: "uint256", internalType: "uint256" },
          {
            name: "startBlock",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "startPrice",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "endPrice",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "lstAmount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "wEthToCompensateBuffer",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "assetsToRepay",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "auctions",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "tuple[]",
        internalType: "struct AuctionData[]",
        components: [
          { name: "id", type: "uint256", internalType: "uint256" },
          {
            name: "startBlock",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "startPrice",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "endPrice",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "lstAmount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "wEthToCompensateBuffer",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "assetsToRepay",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "balanceOf",
    inputs: [{ name: "account", type: "address", internalType: "address" }],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "changeStrategy",
    inputs: [
      {
        name: "targetLeverage",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "maxLeveragedDeposit",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "closeAllPositions",
    inputs: [
      { name: "price", type: "uint256", internalType: "uint256" },
      {
        name: "slippage",
        type: "tuple",
        internalType: "struct Fee.Data",
        components: [
          { name: "value", type: "uint256", internalType: "uint256" },
          {
            name: "precision",
            type: "uint256",
            internalType: "uint256",
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "closeAuction",
    inputs: [{ name: "auctionId", type: "uint256", internalType: "uint256" }],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "convertToAssets",
    inputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    outputs: [{ name: "assets", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "convertToShares",
    inputs: [{ name: "assets", type: "uint256", internalType: "uint256" }],
    outputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "currentAuctionPrice",
    inputs: [{ name: "auctionId", type: "uint256", internalType: "uint256" }],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "deposit",
    inputs: [
      { name: "assets", type: "uint256", internalType: "uint256" },
      { name: "receiver", type: "address", internalType: "address" },
    ],
    outputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "depositEth",
    inputs: [],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "depositLst",
    inputs: [
      { name: "lstAmount", type: "uint256", internalType: "uint256" },
      { name: "receiver", type: "address", internalType: "address" },
    ],
    outputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "maxDeposit",
    inputs: [{ name: "", type: "address", internalType: "address" }],
    outputs: [{ name: "maxAssets", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "maxLstDeposit",
    inputs: [],
    outputs: [{ name: "maxLst", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "maxMint",
    inputs: [{ name: "", type: "address", internalType: "address" }],
    outputs: [{ name: "maxShares", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "maxRedeem",
    inputs: [{ name: "tokenOwner", type: "address", internalType: "address" }],
    outputs: [{ name: "maxShares", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "maxWithdraw",
    inputs: [{ name: "tokenOwner", type: "address", internalType: "address" }],
    outputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "mint",
    inputs: [
      { name: "shares", type: "uint256", internalType: "uint256" },
      { name: "receiver", type: "address", internalType: "address" },
    ],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "name",
    inputs: [],
    outputs: [{ name: "", type: "string", internalType: "string" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "pauseIncreaseLeverage",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "previewDeposit",
    inputs: [{ name: "assets", type: "uint256", internalType: "uint256" }],
    outputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "previewLstDeposit",
    inputs: [{ name: "lstAmount", type: "uint256", internalType: "uint256" }],
    outputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "previewMint",
    inputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    outputs: [{ name: "assets", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "previewRedeem",
    inputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    outputs: [{ name: "assets", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "previewWithdraw",
    inputs: [{ name: "assets", type: "uint256", internalType: "uint256" }],
    outputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "receiveFlashLoan",
    inputs: [
      {
        name: "tokens",
        type: "address[]",
        internalType: "contract IERC20[]",
      },
      { name: "amounts", type: "uint256[]", internalType: "uint256[]" },
      {
        name: "feeAmounts",
        type: "uint256[]",
        internalType: "uint256[]",
      },
      { name: "", type: "bytes", internalType: "bytes" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "redeem",
    inputs: [
      { name: "shares", type: "uint256", internalType: "uint256" },
      { name: "receiver", type: "address", internalType: "address" },
      { name: "tokenOwner", type: "address", internalType: "address" },
    ],
    outputs: [{ name: "assets", type: "uint256", internalType: "uint256" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "resumeIncreaseLeverage",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setBufferSize",
    inputs: [{ name: "bufferSize", type: "uint256", internalType: "uint256" }],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setDexSlippage",
    inputs: [
      {
        name: "slippage",
        type: "tuple",
        internalType: "struct Fee.Data",
        components: [
          { name: "value", type: "uint256", internalType: "uint256" },
          {
            name: "precision",
            type: "uint256",
            internalType: "uint256",
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setFeeCollector",
    inputs: [
      {
        name: "_feeCollector",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setMaxBorrowCoeff",
    inputs: [
      { name: "protocol", type: "uint256", internalType: "uint256" },
      { name: "maxBorrowCoeff", type: "uint24", internalType: "uint24" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setSupportedInterface",
    inputs: [
      { name: "interfaceId", type: "bytes4", internalType: "bytes4" },
      { name: "isSupported", type: "bool", internalType: "bool" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "symbol",
    inputs: [],
    outputs: [{ name: "", type: "string", internalType: "string" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "targetLeverage",
    inputs: [],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "totalAssets",
    inputs: [],
    outputs: [
      {
        name: "totalManagedAssets",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "totalSupply",
    inputs: [],
    outputs: [{ name: "", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "transfer",
    inputs: [
      { name: "to", type: "address", internalType: "address" },
      { name: "value", type: "uint256", internalType: "uint256" },
    ],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "transferFrom",
    inputs: [
      { name: "from", type: "address", internalType: "address" },
      { name: "to", type: "address", internalType: "address" },
      { name: "value", type: "uint256", internalType: "uint256" },
    ],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "withdraw",
    inputs: [
      { name: "assets", type: "uint256", internalType: "uint256" },
      { name: "receiver", type: "address", internalType: "address" },
      { name: "tokenOwner", type: "address", internalType: "address" },
    ],
    outputs: [{ name: "shares", type: "uint256", internalType: "uint256" }],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "withdrawFee",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "tuple",
        internalType: "struct Fee.Data",
        components: [
          { name: "value", type: "uint256", internalType: "uint256" },
          {
            name: "precision",
            type: "uint256",
            internalType: "uint256",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
]; // The ABI for your contract

export const stETHContractAddress =
  "0xae7ab96520de3a18e5e111b5eaab095312d7fe84"; // stETH contract address
export const wstETHContractAddress =
  "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0"; // wstETH contract address
export const pwstETHContractAddress =
  "0x2CAad4425c69481fc40FB07812973e15B36C462d"; // pwstETH contract address
export const wEthContractAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";

// The ABI for ERC20 token's balanceOf method is the same
export const erc20ABI = [
  // Only include the relevant part of the ABI
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    type: "function",
    name: "allowance",
    inputs: [
      {
        name: "owner",
        type: "address",
        internalType: "address",
      },
      {
        name: "spender",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  // ... other methods if needed
];

export const POWER_LIDO_PRECISION = 10 ** 21;
