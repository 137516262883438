import React, { useState, useEffect } from 'react';
import './DepositSection.css';
import { useBlockchainData } from './utils/BlockchainDataContext';
import * as utils from "./utils/Utils"
import { VaultContractAddress } from './utils/VaultContract';
import { toast } from "react-toastify";

const DepositSection = () => {

  const DepositType = {
    ETH: "eth",
    WETH: "weth",
    WSTETH: "wsteth"
  }

  const [depositType, setDepositType] = useState(DepositType.ETH)
  const [amount, setAmount] = useState('');
  const {
    loadBlockchainData,
    account,
    web3,
    maxDepositEth,
    maxDepositLst,
    userEthBalance,
    wEthBalance,
    wstETHBalance,
    wEthAllowance,
    wstEthAllowance,
    wstEthPriceUsd,
    wEthPriceUsd
  } = useBlockchainData();
  const [maxDepositRounded, setMaxDepositRounded] = useState()
  const [ previewPWL, setPreviewPWL ] = useState(0)
  const [ tokenPrice, setTokenPrice ] = useState()

  useEffect(() => {
    if (!account) {
      return
    }

    setTokenPrice(depositType === DepositType.WSTETH ? wstEthPriceUsd : wEthPriceUsd)
  }, [depositType, wstEthPriceUsd, wEthPriceUsd])

  useEffect(() => {
    const userMaxDeposit = (() => {
      switch(depositType) {
        case DepositType.ETH:
          return Math.min(userEthBalance, maxDepositEth)
          case DepositType.WETH:
          return Math.min(wEthBalance, maxDepositEth, wEthAllowance)
        default:
          return Math.min(wstETHBalance, maxDepositLst, wstEthAllowance)
      }
    })()
    setMaxDepositRounded(userMaxDeposit ? Math.trunc(userMaxDeposit.toString() * 1000000).toString() / 1000000 : '0')
  }, [maxDepositEth, userEthBalance, depositType, maxDepositLst, wstETHBalance, wEthBalance, wstEthAllowance, wEthAllowance])

  const handleMaxClick = () => {
    setAmount(maxDepositRounded);
    // You'll also want to convert maxAmount to the corresponding dollar value and set it in state
  };

  const deposit = async () => {
    if (!account) {
      toast.warn("Please, connect MetaMask wallet")
      return
    }
    
    if (amount <= 0) {
      toast.warn("Please, enter correct amount")
      return
    }

    const depositFunc = (() => {
      switch(depositType) {
        case DepositType.ETH:
          return utils.depositEth
        case DepositType.WETH:
          return utils.deposit
        default:
          return utils.depositLst
      }
    })()
    const pwlBalance = await utils.fetchTokenBalance(web3, VaultContractAddress, account)
    console.log(pwlBalance)
    var needSuggestToken = pwlBalance == 0

    depositFunc(web3, amount, account).then(() => {
      setAmount(0)
      loadBlockchainData()
      if (needSuggestToken) {
        utils.suggestPowerLidoToken()
      }
    }).catch(() => {
      toast.warn("Deposit error.\nPlease, refresh the page and try again.")
    })
  }

  useEffect(() => {
    if (!account) {
      return
    }
    const previewFunc = (depositType === DepositType.WSTETH ? utils.previewLstDeposit : utils.previewDeposit)
    previewFunc(web3, amount).then((shares) => {
      setPreviewPWL(Math.trunc(shares * 1000000) / 1000000)
    })
  }, [amount, depositType])

  const getAppropriateString = () => {
    switch(depositType) {
      case DepositType.ETH:
        return "ETH"
      case DepositType.WETH:
        return "WETH"
      default:
        return "WSTETH"
    }
  }

  return (
    <div className="deposit-section">
      <div className="deposit-header">
        <select
          className="token-select"
          id="steth"
          value={depositType}
          onChange={(e) => setDepositType(e.target.value)} >
          <option value={DepositType.ETH}>
            ETH
          </option>
          <option value={DepositType.WETH}>
            WETH
          </option>
          <option value={DepositType.WSTETH}>
            WSTETH
          </option>
          {/* other options */}
        </select>
      </div>
      <div className="deposit-body">
        <label htmlFor="amount">Deposit</label>
        <div className="input-group">
          <input
            type="text"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="0.00"
          />
          <button onClick={handleMaxClick}>Use Max (~{maxDepositRounded} {getAppropriateString()})</button>
        </div>
        <div className="conversion">
          <span>Expected</span>
          <span> ~{previewPWL} PWL ({amount ? `$${(amount * (tokenPrice ? tokenPrice : "0")).toFixed(2)}` : '$0.00'})</span>

        </div>
        <div className="">
        </div>
        <button className="deposit-button"
                onClick ={deposit}>Deposit</button>
      </div>
    </div>
  );
};

export default DepositSection;
