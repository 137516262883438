import Web3 from "web3";
import {
  VaultContractABI,
  VaultContractAddress,
  erc20ABI,
  POWER_LIDO_PRECISION,
} from "./VaultContract";

import { AAVE_ORACLE_ABI, AAVE_ORACLE_ADDRESS } from "./AaveOracleContract.js";

import { toast } from "react-toastify";

const TESTNET_URL = "https://testnetrpc.leveragedstake.com/";
const LAMBDA_FUNCTION_URL =
  "https://qhajtvrrjf2muv5ru62tjxxxla0hfrds.lambda-url.eu-north-1.on.aws/";

const ZERO_ADDRESS = "0x" + "0".repeat(40);

const toastOptions = { position: "bottom-right", theme: "dark" };
const showSuccessToast = (message) => toast.success(message, toastOptions);

const showError = (message, error) => {
  console.error(message + ":", error);
  if (error.message) {
    message += ": " + error.message;
  }
  toast.error(message, toastOptions);
};

// Function to connect to MetaMask
export const connectToMetaMask = async () => {
  if (window.ethereum) {
    // Check if MetaMask is installed
    try {
      const web3 = new Web3(window.ethereum);
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      }); // Request access
      const account = accounts[0];
      return { web3, account };
    } catch (error) {
      showError("User denied account access or error occurred", error);
    }
  } else {
    showError("MetaMask is not installed!", {});
  }
  return {};
};

export const mintEther = async (wei, userAddress) => {
  try {
    const response = await fetch(LAMBDA_FUNCTION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ wei, userAddress }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    showSuccessToast(data.message);
  } catch (error) {
    console.error("Error minting ether:", error);
    showError(
      "Error minting ether, please, refresh the page and try again",
      error.message
    );
  }
};

// Fetch total supply from the smart contract
export const fetchTotalSupply = async (web3) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    const totalSupply = await contract.methods.totalSupply().call();
    return totalSupply;
  } catch (error) {
    showError("Error fetching total supply", error);
  }
};

// Fetch user's ETH balance
export const fetchUserEthBalance = async (web3, userAddress) => {
  try {
    const balanceWei = await web3.eth.getBalance(userAddress);
    const balanceEth = web3.utils.fromWei(balanceWei, "ether");
    return balanceEth;
  } catch (error) {
    showError("Error fetching user's ETH balance", error);
  }
};

export const fetchTokenBalance = async (web3, contractAddress, userAddress) => {
  const contract = new web3.eth.Contract(erc20ABI, contractAddress);
  try {
    const balanceWei = await contract.methods.balanceOf(userAddress).call();
    const balance = web3.utils.fromWei(balanceWei.toString(), "ether");
    return balance;
  } catch (error) {
    showError("Error fetching token balance", error);
  }
};

export const fetchTokenAllowance = async (
  web3,
  contractAddress,
  userAddress
) => {
  const contract = new web3.eth.Contract(erc20ABI, contractAddress);
  try {
    const balanceWei = await contract.methods
      .allowance(userAddress, VaultContractAddress)
      .call();
    const balance = web3.utils.fromWei(balanceWei.toString(), "ether");
    return balance;
  } catch (error) {
    showError("Error fetching token balance", error);
  }
};

export const fetchTotalAssets = async (web3) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    const totalAssets = await contract.methods.totalAssets().call();
    return totalAssets;
  } catch (error) {
    showError("Error fetching total assets", error);
  }
};

export const fetchMaxDeposit = async (web3) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    const maxAssets = await contract.methods.maxDeposit(ZERO_ADDRESS).call();
    return web3.utils.fromWei(maxAssets, "ether");
  } catch (error) {
    showError("Error preview withdraw", error);
  }
};

export const fetchMaxLstDeposit = async (web3) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    const maxLst = await contract.methods.maxLstDeposit(ZERO_ADDRESS).call();
    return web3.utils.fromWei(maxLst, "ether");
  } catch (error) {
    showError("Error preview withdraw", error);
  }
};

export const fetchMaxRedeem = async (web3, userAddress) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    const maxShares = await contract.methods.maxRedeem(userAddress).call();
    return maxShares.toString() / POWER_LIDO_PRECISION;
  } catch (error) {
    showError("Error fetching max redeem", error);
  }
};

export const fetchTargetLeverage = async (web3) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    const leverage = await contract.methods.targetLeverage().call();
    return leverage / BigInt(2 ** 10);
  } catch (error) {
    console.error("Error fetching target leverage:", error);
  }
};

export const fetchWithdrawFee = async (web3) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    const { value, precision } = await contract.methods.withdrawFee().call();
    return parseFloat(value) / parseFloat(precision);
  } catch (error) {
    console.error("Error fetching withdraw fee:", error);
  }
};

export const previewDeposit = async (web3, amount) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    amount = web3.utils.toWei(amount, "ether");
    var shares = await contract.methods.previewDeposit(amount).call();
    shares = shares.toString() / POWER_LIDO_PRECISION;
    return shares;
  } catch (error) {
    showError("Error preview deposit", error);
  }
};

export const previewLstDeposit = async (web3, lst) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    lst = web3.utils.toWei(lst, "ether");
    var shares = await contract.methods.previewLstDeposit(lst).call();
    shares = shares.toString() / POWER_LIDO_PRECISION;
    return shares;
  } catch (error) {
    showError("Error preview lst deposit", error);
  }
};

export const previewRedeem = async (web3, shares) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    if (shares == 0) {
      return 0;
    }
    shares = shares * POWER_LIDO_PRECISION;
    const assets = await contract.methods.previewRedeem(shares).call();
    return web3.utils.fromWei(assets, "ether");
  } catch (error) {
    showError("Error preview redeem", error);
  }
};

export const redeem = async (web3, amount, userAddress) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    await contract.methods
      .redeem(amount * POWER_LIDO_PRECISION, userAddress, userAddress)
      .send({ from: userAddress });
    showSuccessToast(`successfully redeemed ${amount} PWL`);
  } catch (error) {
    showError("Error withdrawing pwl", error);
  }
};

export const depositEth = async (web3, amount, userAddress) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    await contract.methods
      .depositEth()
      .send({ from: userAddress, value: web3.utils.toWei(amount, "ether") });
    showSuccessToast(`successfully deposited ${amount} ETH`);
  } catch (error) {
    showError("Error depositing eth", error);
  }
};

export const deposit = async (web3, amount, userAddress) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    await contract.methods
      .deposit(web3.utils.toWei(amount, "ether"), userAddress)
      .send({ from: userAddress });
    showSuccessToast(`successfully deposited ${amount} ETH`);
  } catch (error) {
    showError("Error depositing weth", error);
  }
};

export const depositLst = async (web3, amount, userAddress) => {
  const contract = new web3.eth.Contract(
    VaultContractABI,
    VaultContractAddress
  );
  try {
    await contract.methods
      .depositLst(web3.utils.toWei(amount, "ether"), userAddress)
      .send({ from: userAddress });
    showSuccessToast(`successfully deposited ${amount} WSTETH`);
  } catch (error) {
    showError("Error depositing wsteth", error);
  }
};

export const fetchAssetPrice = async (web3, asset) => {
  const contract = new web3.eth.Contract(AAVE_ORACLE_ABI, AAVE_ORACLE_ADDRESS);
  try {
    return await contract.methods
      .getAssetPrice(asset)
      .call()
      .then((price) =>
        contract.methods
          .BASE_CURRENCY_UNIT()
          .call()
          .then((unit) => price.toString() / unit.toString())
      );
  } catch (error) {
    showError("Error fetching asset price", error);
  }
};

export const suggestPowerLidoToken = async () => {
  window.ethereum
    .request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: VaultContractAddress,
          symbol: "TBL",
          decimals: 21,
        },
      },
    })
    .catch((error) => showError("Error adding token", error));
};
