import logo from "./logo.svg";
import "./App.css";
import VaultPopup from "./comp/VaultPopup";
import Wallet from "./comp/utils/Wallet";
import { BlockchainDataProvider } from "./comp/utils/BlockchainDataContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Guidance from "./comp/Guidance"

function App() {
  // In your service file or main component
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      // Handle accounts change
    });

    window.ethereum.on("chainChanged", (chainId) => {
      // Handle chain (network) change
    });
  }

  return (
    <div className="App">
      <BlockchainDataProvider>
        <Guidance/>
        <Wallet />
        <VaultPopup />
      </BlockchainDataProvider>
      <ToastContainer toastStyle={{ backgroundColor: "#333" }}/>
    </div>
  );
}

export default App;
