// StrategySection.jsx
import React from 'react';
import './StrategySection.css';
import {useBlockchainData} from './utils/BlockchainDataContext'

const StrategySection = () => {
  const{targetLeverage} = useBlockchainData()
  // Dummy data for the parameters section
  const parameters = {
    'Target leverage': parseFloat(targetLeverage).toFixed(2).toString() + 'x',
    'Risk Level': 'Moderate',
    'Min. Deposit': '0.01 ETH',
    // Add other parameters here
  };

  return (
    <div className="strategy-section">
      <h3>Strategy</h3>
      <div className="description">
        <p>
          This strategy focuses on maximizing stETH yield by leveraging Lido staking rewards through recursive borrowing form varuious lending protocols and keeping the lowest borrowing rate.
        </p>
      </div>
      <div className="parameters">
        {Object.entries(parameters).map(([key, value]) => (
          <div key={key} className="parameter">
            <span className="key">{key}: </span>
            <span className="value">{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StrategySection;
