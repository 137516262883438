// VaultInfo.jsx (This is your main component for the left column)
import React from 'react';
// import APRSection from './APRSection'; // Adjust this if your component has a different name
// import TVLSection from './TVLSection'; // You may need to create this component
// import VaultSection from './VaultSection';
import StrategySection from './StrategySection'; // This is a new component
import './VaultInfo.css'; // This will be your CSS file for styling
import IntegratedProtocolsSection from './IntegratedProtocolsSection';

const VaultInfo = () => {
  return (
    <div className="vault-info">
      {/* <APRSection /> */}
      {/* <TVLSection /> */}
        {/* <VaultSection/> */}
      <IntegratedProtocolsSection /> 
      <StrategySection />
    </div>
  );
};

export default VaultInfo;
