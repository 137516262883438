// WithdrawSection.jsx
import React, { useEffect, useState } from 'react';
import './WithdrawSection.css';
import { useBlockchainData } from './utils/BlockchainDataContext';
import * as utils from "./utils/Utils"

const WithdrawSection = () => {

  const [ sharesAmount, setSharesAmount] = useState(0);
  const { account, web3, loadBlockchainData, maxRedeemPWL, wEthPriceUsd, withdrawFee } = useBlockchainData();

  const [ maxSharesRounded, setMaxSharesRounded]  = useState(0)
  const [ expectedAssets, setExpectedAssets]  = useState(0)
  const [ expectedETHWithdrawnFee, setExpectedETHWithdrawnFee]  = useState(0)
  const [ tokenPrice, setTokenPrice ] = useState()

  useEffect(() => {
    if (!account) {
      return
    }

    setTokenPrice(wEthPriceUsd)
  }, [wEthPriceUsd])

  useEffect(() => {
    // @todo 0.9 - is hack to print amount that will be acceptable. Now aave takes
    // fee every block so maxWithdraw() result in current block not valid anymore in the
    // next one
    const maxPWL = maxRedeemPWL * 0.9
    const res = maxPWL ? Math.trunc(maxPWL * 1000000) / 1000000 : '0'

    setMaxSharesRounded(res)
  }, [maxRedeemPWL])

  useEffect(() => {
    if (!account) {
      return
    }

    const _expectedETHWithdrawnFee = sharesAmount * withdrawFee
    setExpectedETHWithdrawnFee(_expectedETHWithdrawnFee)
    utils.previewRedeem(web3, sharesAmount).then((assets) => {
      setExpectedAssets(Math.trunc(assets * 1000000) / 1000000)
    })
  }, [sharesAmount, withdrawFee])

  const handleMaxWithdrawClick = () => {
    setSharesAmount(maxSharesRounded); // TODO: it's not okay, but I'm not in the mood to fix styling
  };

  const withdraw = () => {
    utils.redeem(web3, sharesAmount, account).then(() => {
      setSharesAmount(0)
      loadBlockchainData()
    })
  }

  return (
    <div className="withdraw-section">
      <div className="withdraw-body">
        <label htmlFor="withdraw-amount">Withdraw</label>
        <div className="input-group">
          <input
            type="text"
            id="withdraw-amount"
            value={sharesAmount}
            onChange={(e) => setSharesAmount(e.target.value)}
            placeholder="0.00"
          />
          <button onClick={handleMaxWithdrawClick}>Use Max (~{maxSharesRounded} PWL)</button>
        </div>
        {/* <div className="conversion">
          Add conversion logic here
        </div> */}
        <div className='withdraw-header'>
        {/* <div className="available-balance">
        <span>Available</span>
        <span>19693 ETH / $36M</span>
      </div> */}
          <select
            className="token-select"
            id="steth">
              <option value='weth'>
                WETH
              </option>
              {/* other options */}
          </select>
        </div>
        <div className="expected">
          <span>Expected</span>
          <span>
            ~{expectedAssets} WETH (${(expectedAssets * (tokenPrice ?? 0)).toFixed(2)})</span>
        </div>
        <div className="fee">
          <span>Withdraw fee</span>
          <span>{withdrawFee * 100}% (${(expectedETHWithdrawnFee * (tokenPrice ?? 0)).toFixed(2)})</span>
        </div>
        <button className="withdraw-button"
                onClick={withdraw}>Withdraw</button>
      </div>
    </div>
  );
};

export default WithdrawSection;
