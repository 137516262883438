import React, { useState } from 'react';
import { connectToMetaMask, mintEther } from "./Utils";
import './Wallet.css'
import { useBlockchainData } from './BlockchainDataContext';

export const MINT_AMOUNT_ETH = 10
const Wallet = () => {
  const { account, setAccount, setWeb3 } = useBlockchainData();
  const [isConnected, setIsConnected] = useState(false);
  const [isMinting, setIsMinting] = useState(false);

  const handleConnect = async () => {
    if (isConnected)
      return;

    const { web3, account } = await connectToMetaMask();
    if (account) {
      setWeb3(web3);
      setAccount(account);
      setIsConnected(true);
      // Perform additional actions like updating the UI or fetching balance
    }
  };

  const handleMint = async () => {
    setIsMinting(true);
    await mintEther(MINT_AMOUNT_ETH * 10**18, account);
    setIsMinting(false);
  };

  return (
    <div className="wallet">
      <button
        className="mint-eth-button"
        disabled={!isConnected || isMinting}
        onClick={handleMint}
      >
        {isMinting ? (
          <div className="loader"></div>
        ) : (
          `Mint ${MINT_AMOUNT_ETH} ether`
        )}
      </button>
      <button
        onClick={handleConnect}
        className={isConnected ? "connected" : ""}
      >
        {isConnected
          ? `Connected: ${account.slice(0, 7)}...${account.slice(37, 43)}`
          : "Connect to MetaMask"}
      </button>
    </div>
  );
};

export default Wallet;
